<template>
  <div>
    <div style="font-size: 20px">开课概览</div>
    <el-divider class="margin10"></el-divider>
    <div class="previewTopList">
      <div class="previewLi">
        <div class="top">
          实开课时
          <el-tooltip
            effect="dark"
            content="当前学期内该学校已完成开课凭证上传的课程课时数；（一节课为1课时）"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.completeLessonCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          应开课时
          <el-tooltip
            effect="dark"
            content="截止当前时间内，按教学计划中应开课的课时数；（一节课为1课时）"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.shouldLessonCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          总课时
          <el-tooltip
            effect="dark"
            content="该学期教学计划内该学校的课时总和;"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.lessonCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          课程开出率
          <el-tooltip
            effect="dark"
            content="已上传开课凭证课程/教学计划总课时（一节课为1课时）"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.openLessonRate }}</div>
      </div>
    </div>
    <div style="font-size: 20px">实验课课程表</div>
    <el-divider class="margin10"></el-divider>
    <!-- 发起手机任务 -->
    <!-- <el-row style="margin-bottom: 20px"></el-row> -->
    <!-- 条件 -->
    <el-row style="margin-bottom: -20px">
      <el-form :inline="true" ref="conditionForm" :model="conditionForm">
        <el-form-item label="学年/学期" class="formItemBoxStyle">
          <el-cascader
            style="width: 100%"
            v-model="sysSchoolTermIdArr"
            :options="$store.state.public_data.sysAcademicTermYearDicList"
            :props="{
              children: 'children',
            }"
            @change="sysSchoolTermIdChange"
            clearable
          ></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="学校" class="formItemBoxStyle">
          <el-select
            v-model="conditionForm.sysOrgSchoolId"
            @change="changeConditionForm"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.sysOrgSchoolIdList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="学段" class="formItemBoxStyle">
          <el-select
            v-model="conditionForm.schoolSectionDicId"
            @change="changeSchoolSectionDicId"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.schoolSectionDicIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级" class="formItemBoxStyle">
          <el-select
            v-model="conditionForm.sysSchoolClassGradeId"
            @change="changeConditionForm"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.sysSchoolClassGradeIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科目" class="formItemBoxStyle">
          <el-select
            v-model="conditionForm.sysSubjectDicId"
            @change="changeConditionForm"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.subjectTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="班级名称" class="formItemBoxStyle">
          <el-input
            v-model="conditionForm.sysOrgSchoolClassName"
            @change="changeConditionForm"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="inquireBtn" type="primary">查询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <div style="display: flex; justify-content: end">
      <el-button
        type="text"
        @click="$router.push({ path: '/courseStart/curriculum' })"
        >列表/日历</el-button
      >
    </div>
    <!-- 表格 -->
    <el-row>
      <el-table
        v-loading="loading"
        border
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50px"
        ></el-table-column>
        <el-table-column prop="sysSubjectDicId" label="所属科目" width="90">
          <template slot-scope="scope">
            {{ scope.row.sysSubjectDic?.sysDicName }}
          </template>
        </el-table-column>
        <el-table-column prop="crsExpCourseName" label="课程名称">
          <template slot-scope="scope">
            <el-link type="primary" @click="courseDetailsBtn(scope.row)">{{
              scope.row.crsExpCourseName
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="sysOrgSchoolRoomName"
          label="上课地点"
        ></el-table-column>
        <el-table-column prop="schoolTime" label="上课日期" width="110">
          <template slot-scope="scope">
            {{ scope.row.schoolTime | dayFilters }}
          </template>
        </el-table-column>
        <el-table-column prop="sysSchoolClassSession" label="节次" width="110">
          <template slot-scope="scope">
            {{ scope.row.sysSchoolClassSession?.sysSchoolClassSessionName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sysOrgSchoolClassName"
          label="上课班级"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="sysOrgSchoolClassStudentCount"
          label="班级人数"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="teacherName"
          label="任课老师"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="managerName"
          label="实验管理员"
          width="100"
        ></el-table-column>
        <el-table-column prop="crsOpenStatus" label="提交状态" width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.crsOpenStatus == 1">未提交</span>
            <span v-else-if="scope.row.crsOpenStatus == 2">已提交</span>
            <span v-else-if="scope.row.crsOpenStatus == 3">驳回提交</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column label="开课凭证" width="90">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="courseVoucherBtn(scope.row)"
              >预览</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="approveStatus" label="审批状态" width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.approveStatus == 1">未审批</span>
            <span v-else-if="scope.row.approveStatus == 2">审批通过</span>
            <span v-else-if="scope.row.approveStatus == 3">审批不通过</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="updateBtn(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>

    <!-- 执行学校弹框 -->
    <el-dialog width="600px" title="编辑课程" :visible.sync="dialogVisible">
      <!--  -->
      <el-form
        :model="dialogVisibleData"
        ref="dialogVisibleData"
        :rules="dialogVisibleRules"
        hide-required-asterisk
        label-width="100px"
      >
        <el-form-item style="margin-bottom: 10px" label="所属科目">{{
          dialogVisibleView.sysSubjectDic?.sysDicName
        }}</el-form-item>
        <el-form-item style="margin-bottom: 10px" label="课程名称">{{
          dialogVisibleView.crsExpCourseName
        }}</el-form-item>
        <el-form-item style="margin-bottom: 10px" label="演示实验">
          {{
            coursePlanLi.crsOpenPlanCourseDTO?.demoExpStatus == 1
              ? '必做'
              : coursePlanLi.crsOpenPlanCourseDTO?.demoExpStatus == 2
              ? '选做'
              : ''
          }}
          <!-- {{
            dialogVisibleView.crsOpenPlanCourseDTO?.demoExpStatus == 1
              ? '必做'
              : dialogVisibleView.crsOpenPlanCourseDTO?.demoExpStatus == 2
              ? '选做'
              : ''
          }} -->
        </el-form-item>
        <el-form-item style="margin-bottom: 10px" label="分组实验">
          {{
            coursePlanLi.crsOpenPlanCourseDTO?.groupExpStatus == 1
              ? '必做'
              : coursePlanLi.crsOpenPlanCourseDTO?.groupExpStatus == 2
              ? '选做'
              : ''
          }}
          <!-- {{
            dialogVisibleView.crsOpenPlanCourseDTO?.groupExpStatus == 1
              ? '必做'
              : dialogVisibleView.crsOpenPlanCourseDTO?.groupExpStatus == 2
              ? '选做'
              : ''
          }} -->
        </el-form-item>
        <el-form-item style="margin-bottom: 10px" label="上课班级">{{
          dialogVisibleView.sysOrgSchoolClassName
        }}</el-form-item>
        <el-form-item style="margin-bottom: 10px" label="班级人数">{{
          dialogVisibleView.sysOrgSchoolClassStudentCount
        }}</el-form-item>
        <el-form-item style="margin-bottom: 10px" label="上课地点">{{
          dialogVisibleView.sysOrgSchoolRoomName
        }}</el-form-item>
        <el-form-item label="上课日期" prop="schoolTime">
          <el-date-picker
            style="width: 100%"
            v-model="dialogVisibleData.schoolTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="timestamp"
            format="yyyy-MM-dd"
            default-time="00:00:00"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="节次" prop="sysSchoolClassSessionId">
          <el-select
            style="width: 100%"
            v-model="dialogVisibleData.sysSchoolClassSessionId"
          >
            <el-option
              v-for="item in $store.state.public_data.sysSessionDataDicList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任课老师" prop="teacherId">
          <el-select style="width: 100%" v-model="dialogVisibleData.teacherId">
            <el-option
              v-for="item in dialogVisibleCondition.teacherIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实验室管理员" prop="managerId">
          <el-select style="width: 100%" v-model="dialogVisibleData.managerId">
            <el-option
              v-for="item in dialogVisibleCondition.managerIdList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          size="small"
          @click="dialogSaveBtn('dialogVisibleData')"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <!-- 课程详情 -->
    <el-dialog
      title="课程详情"
      :visible.sync="dialogCourseDetailsBtn"
      width="800px"
    >
      <div style="display: flex">
        <div style="width: 50%">
          <el-form :model="coursePlanLi" ref="coursePlanLi" label-width="90px">
            <el-form-item style="margin-bottom: 10px" label="所属科目">{{
              coursePlanLi.sysSubjectDic?.sysDicName
            }}</el-form-item>
            <el-form-item style="margin-bottom: 10px" label="课程名称">{{
              coursePlanLi.crsExpCourseName
            }}</el-form-item>
            <el-form-item style="margin-bottom: 10px" label="演示实验">
              {{
                coursePlanLi.crsOpenPlanCourseDTO?.demoExpStatus == 1
                  ? '必做'
                  : coursePlanLi.crsOpenPlanCourseDTO?.demoExpStatus == 2
                  ? '选做'
                  : ''
              }}
            </el-form-item>
            <el-form-item style="margin-bottom: 10px" label="分组实验"
              >{{
                coursePlanLi.crsOpenPlanCourseDTO?.groupExpStatus == 1
                  ? '必做'
                  : coursePlanLi.crsOpenPlanCourseDTO?.groupExpStatus == 2
                  ? '选做'
                  : ''
              }}
            </el-form-item>
            <el-form-item style="margin-bottom: 10px" label="上课班级">{{
              coursePlanLi.sysOrgSchoolClassName
            }}</el-form-item>
            <el-form-item style="margin-bottom: 10px" label="班级人数">{{
              coursePlanLi.sysOrgSchoolClassStudentCount
            }}</el-form-item>
            <el-form-item style="margin-bottom: 10px" label="上课地点">{{
              coursePlanLi.sysOrgSchoolRoomName
            }}</el-form-item>
            <el-form-item style="margin-bottom: 10px" label="上课日期">
              <el-date-picker
                v-model="coursePlanLi.schoolTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="timestamp"
                format="yyyy-MM-dd"
                default-time="00:00:00"
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item style="margin-bottom: 10px" label="节次">{{
              coursePlanLi.sysSchoolClassSession?.sysSchoolClassSessionName
            }}</el-form-item>
            <el-form-item style="margin-bottom: 10px" label="任课老师">{{
              coursePlanLi.teacherName
            }}</el-form-item>
            <el-form-item style="margin-bottom: 10px" label="实验管理员">{{
              coursePlanLi.managerName
            }}</el-form-item>
            <el-form-item style="margin-bottom: 10px" label="状态"
              >{{
                coursePlanLi.approveStatus == 1
                  ? '未提交'
                  : coursePlanLi.approveStatus == 2
                  ? '已提交'
                  : '驳回提交'
              }}
            </el-form-item>
          </el-form>
        </div>
        <div
          style="
            background-color: #f2f2f2;
            width: 50%;
            box-sizing: border-box;
            padding: 10px 10px 0 10px;
          "
        >
          课程开展情况
          <div
            style="
              width: 100%;
              height: 200px;
              overflow-y: auto;
              background-color: #fff;
              margin: 10px 0;
            "
          >
            {{ coursePlanLi.crsOpenVoucherMark }}
          </div>
          开课凭证
          <div
            style="
              width: 100%;
              margin: 10px 0;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            "
          >
            <img
              style="width: calc(50% - 5px)"
              v-for="item in coursePlanLi.crsOpenVoucherList"
              :key="item"
              :src="$imageAddress(item)"
            />
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 凭证预览 -->
    <el-dialog
      title="开课凭证"
      :visible.sync="dialogCourseVoucher"
      width="600px"
    >
      <div
        style="
          background-color: #f2f2f2;
          box-sizing: border-box;
          padding: 10px 10px 0 10px;
        "
      >
        课程开展情况
        <div
          style="
            width: 100%;
            height: 200px;
            overflow-y: auto;
            background-color: #fff;
            margin: 10px 0;
          "
        >
          {{ coursePlanLi.crsOpenVoucherMark }}
        </div>
        开课凭证
        <div
          style="
            width: 100%;
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
          <img
            style="width: calc(50% - 5px)"
            v-for="item in coursePlanLi.crsOpenVoucherList"
            :key="item"
            :src="$imageAddress(item)"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getStore } from '@/utils/token.js'
import { getTeacher } from '@/api/courseStart/scheduleLessonsManage.js'
import { getSchoolRoomDetailApi } from '@/api/mechanism/schoolRoomManage.js'
import {
  getCourseOverview,
  getCoursePlanList,
  getCoursePlanLi,
  updateCoursePlan,
} from '@/api/courseStart/experimentCurriculum.js'
export default {
  name: 'experimentCurriculum',
  data() {
    const requiredRules = { required: true, message: '必填项', trigger: 'blur' }
    return {
      planOverview: {
        lessonCount: 0,
        completeLessonCount: 0,
        openLessonRate: 0,
        shouldLessonCount: 0,
      },
      conditionForm: {
        // crsOpenPlanId: '',
        schoolSectionDicId: '',
        sysOrgSchoolClassId: '',
        // sysOrgSchoolClassName: '',
        sysOrgSchoolId: '',
        sysSchoolClassGradeId: '',
        sysSchoolTermId: '',
        sysSubjectDicId: '',
      },
      sysSchoolTermIdArr: [],
      conditionList: {
        // 年级
        sysSchoolClassGradeIdList: [],
        // 学年学期id集合
        sysSchoolTermIdList: [],
        // 学段字典id集合 年级
        schoolSectionDicIdList: [],
        // 学校id集合
        sysOrgSchoolIdList: [],
        // 学科
        subjectTypeList: [],
      },
      loading: false,
      tableData: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 2,
      },
      // 编辑
      dialogVisible: false,
      dialogVisibleView: {},
      dialogVisibleData: {},
      dialogVisibleCondition: {},
      dialogVisibleCrsOpenPlanLessonId: '',
      dialogVisibleRules: {
        schoolTime: [requiredRules],
        sysSchoolClassSessionId: [requiredRules],
        teacherId: [requiredRules],
        managerId: [requiredRules],
      },
      // 课程详情
      dialogCourseDetailsBtn: false,
      // 凭证预览
      dialogCourseVoucher: false,
      coursePlanLi: {},
    }
  },
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    await this.$store.dispatch('sessionDic')
    // 获取学年
    this.conditionList.sysSchoolTermIdList =
      this.$store.state.public_data.sysAcademicYearDicList
    // 获取学段
    this.conditionList.schoolSectionDicIdList =
      this.$store.state.public_data.sysSemesterTermGradeDicList
    this.conditionList.subjectTypeList =
      this.$store.state.public_data.sysSubjectDicList
  },
  mounted() {
    this.conditionForm.sysOrgSchoolId = Number(getStore('sysOrgSchoolId'))
    this.getCourseOverviewFunc({
      sysOrgSchoolId: Number(getStore('sysOrgSchoolId')),
    })
    this.getCoursePlanListFunc({
      sysOrgSchoolId: Number(getStore('sysOrgSchoolId')),
    })
  },
  methods: {
    // 请求任课老师
    async getTeacherIdListFunc(key) {
      try {
        const res = await getTeacher(1, 10000, key.crsOpenPlanId, {
          sysSubjectDicId: key.sysSubjectDicId,
        })
        console.log('请求任课老师', res)
        if (res.success) {
          return res.data
        } else {
          // this.$message.warning(res.msg)
          return []
        }
      } catch (error) {}
    },
    // 请求该功能室下的管理员
    async getSchoolRoomDetailApiFunc(key) {
      try {
        const res = await getSchoolRoomDetailApi(key)
        console.log('请求任课老师', res)
        if (res.success) {
          return res.data.administratorUserAdminDTOList
        } else {
          // this.$message.warning(res.msg)
          return []
        }
      } catch (error) {}
    },
    // 开课概览
    getCourseOverviewFunc(data) {
      getCourseOverview(data)
        .then((res) => {
          if (res.success) {
            let {
              lessonCount,
              completeLessonCount,
              openLessonRate,
              shouldLessonCount,
            } = res.data
            this.planOverview = {
              lessonCount,
              completeLessonCount,
              openLessonRate,
              shouldLessonCount,
            }
          }
        })
        .catch((err) => {})
    },
    // 分页查询课程节次
    getCoursePlanListFunc(data) {
      getCoursePlanList(this.page.pageIndex, this.page.pageSize, data)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data
            this.page.totalSize = res.total
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 查询单条课程节次信息
    async getCoursePlanLiFunc(key) {
      this.coursePlanLi = {}
      await getCoursePlanLi(key)
        .then((res) => {
          if (res.success) {
            this.coursePlanLi = res.data
          } else {
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    sysSchoolTermIdChange(val) {
      if (!!val) {
        this.conditionForm.sysSchoolTermId = this.sysSchoolTermIdArr[1]
      } else {
        this.conditionForm.sysSchoolTermId = ''
      }
      this.changeConditionForm()
    },
    changeConditionForm() {
      console.log('条件修改', this.conditionForm)
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      // this.getCoursePlanListFunc(this.$qs.stringify(conditionObj))
      this.getCoursePlanListFunc(conditionObj)
    },
    // 修改学段
    async changeSchoolSectionDicId(val) {
      let [arr] = this.$store.state.public_data.sysSemesterTermGradeDicList
        .filter((e) => e.value === val)
        .map((e) => e.children)
      this.conditionList.sysSchoolClassGradeIdList = arr
      this.changeConditionForm()
    },
    // 查询
    inquireBtn() {
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 重置
    resetBtn() {
      for (let key in this.conditionForm) {
        this.conditionForm[key] = ''
      }
      this.conditionForm.sysOrgSchoolId = Number(getStore('sysOrgSchoolId'))
      this.conditionList.sysSchoolClassGradeIdList = []
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 课程名称按钮
    async courseDetailsBtn(row) {
      await this.getCoursePlanLiFunc(row.crsOpenPlanLessonId)
      this.dialogCourseDetailsBtn = true
    },
    // 开课凭证按钮
    async courseVoucherBtn(row) {
      await this.getCoursePlanLiFunc(row.crsOpenPlanLessonId)
      this.dialogCourseVoucher = true
    },
    // 编辑
    async updateBtn(row) {
      // 临时调用
      await this.getCoursePlanLiFunc(row.crsOpenPlanLessonId)

      this.dialogVisibleCrsOpenPlanLessonId = row.crsOpenPlanLessonId
      let { managerId, schoolTime, sysSchoolClassSession, teacherId } = row
      this.dialogVisibleView = row
      this.dialogVisibleData = {
        managerId,
        schoolTime,
        sysSchoolClassSessionId: sysSchoolClassSession.sysSchoolClassSessionId,
        teacherId,
      }
      this.dialogVisibleCondition.teacherIdList = []
      // 任课老师
      this.dialogVisibleCondition.teacherIdList = (
        await this.getTeacherIdListFunc(row)
      ).map((e) => ({
        label: e.teacherName,
        value: e.teacherId,
      }))
      this.dialogVisibleCondition.managerIdList = []
      // 实验管理员
      this.dialogVisibleCondition.managerIdList = (
        await this.getSchoolRoomDetailApiFunc(row.sysOrgSchoolRoomId)
      ).map((e) => ({
        label: e.nickName,
        value: e.userId,
      }))
      this.dialogVisible = true
    },
    // 弹框保存
    dialogSaveBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 编辑
          updateCoursePlan(
            this.dialogVisibleCrsOpenPlanLessonId,
            this.dialogVisibleData
          )
            .then((res) => {
              if (res.success) {
                this.$message.success('保存成功')
                this.dialogVisible = false
                this.resetBtn()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    subjectTypeFilters(key) {
      return this.$store.state.public_data.sysSubjectDicList.find(
        (e) => e.value === key
      )?.name
    },
  },
  watch: {},
  filters: {
    // subjectTypeFilters(key) {
    //  return this.$store.state.public_data.sysSubjectDicList.find(
    //     (e) => e.value === key
    //   )?.name
    // },
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.margin10 {
  margin: 10px 0 20px 0;
}
.previewTopList {
  display: flex;
  margin-left: 30px;
  margin-bottom: 20px;
}
.previewLi {
  margin-right: 50px;
  .top {
    color: #929292;
    font-size: 16px;
    i {
      cursor: pointer;
    }
  }
  .bottom {
    font-size: 30px;
  }
}
</style>
